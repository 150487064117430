<template>
	<b-container fluid>
		<b-row class="justify-content-center wrapper-row" align-v="center">
			<b-col sm="auto">
				<b-card class="trial-wrapper text-center">
					<h4 slot="header">Sign Up</h4>
					<div class="my-4 text-dark mx-auto" v-if="!getclientUserAgreement">
						<h4>Start your 30-day free trial today.</h4>
						<h5>No credit card required.</h5>
					</div>

					<section id="firebaseui-auth-container" />
					<b-card v-if="emailLogin">
						<b-form>
							<b-form-group
								label="Email"
								label-for="input-email"
								class="mb-1"
							>
								<b-form-input
									id="email"
									v-model="email"
									name="email"
									required
									placeholder="Email"
									data-testid="email-to-sign-up"
								/>
							</b-form-group>
							<b-form-group
								label="Password"
								label-for="input-password"
								class="mb-3"
							>
								<b-form-input
									id="password"
									v-model="password"
									name="password"
									type="password"
									placeholder="Password"
									required
									data-testid="password-to-sign-up"
								/>
							</b-form-group>
							<b-button
								variant="primary"
								block
								:disabled="canSubmit || loading"
								@click="createAccount"
								data-testid="create-account-button"
							>
								<b-spinner v-if="loading" small></b-spinner>
								<span v-else>Create Account</span>
							</b-button>
							<div v-if="error">{{ error }}</div>
						</b-form>
					</b-card>
					<div v-else class="mx-auto" style="width: 222px">
						<b-button variant="outline-primary" @click="useEmail" data-testid="sign-up-with-email">
							Sign up with email
						</b-button>
					</div>
					<div class="my-5">
						<b-link @click="hasAccount" data-testid="already-have-an-account">
							Already have an account? Click here.
						</b-link>
					</div>
				</b-card>
				<!-- <div class="d-sm-none d-md-inline circle-box">
					<div class="circle-five">
						<svg
							preserveAspectRatio="none"
							data-bbox="0 0 200 200"
							xmlns="http://www.w3.org/2000/svg"
							width="162"
							height="162"
							viewBox="0 0 200 200"
							role="presentation"
							aria-hidden="true"
						>
							<g>
								<path
									d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
								></path>
							</g>
						</svg>
					</div>
					<div class="circle-six">
						<svg
							preserveAspectRatio="none"
							data-bbox="0 0 200 200"
							xmlns="http://www.w3.org/2000/svg"
							width="76"
							height="76"
							viewBox="0 0 200 200"
							role="presentation"
							aria-hidden="true"
						>
							<g>
								<path
									d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
								></path>
							</g>
						</svg>
					</div>
				</div> -->
			</b-col>
		</b-row>
		<!-- <div class="circle-one">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="200"
				height="200"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div>
		<div class="circle-two">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="162"
				height="162"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div>
		<div class="circle-three">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="162"
				height="162"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div>
		<div class="circle-four">
			<svg
				preserveAspectRatio="none"
				data-bbox="0 0 200 200"
				xmlns="http://www.w3.org/2000/svg"
				width="275"
				height="275"
				viewBox="0 0 200 200"
				role="presentation"
				aria-hidden="true"
			>
				<g>
					<path
						d="M200 100c0 55.228-44.772 100-100 100S0 155.228 0 100 44.772 0 100 0s100 44.772 100 100z"
					></path>
				</g>
			</svg>
		</div> -->
	</b-container>
</template>

<script>
/* global firebaseui */
import isEmail from 'validator/lib/isEmail';
import { auth, googleProvider } from '../firebase';
import * as fb from '../firebase';
import clientUserFormHelper from '@/mixins/clientUserFormHelper';
import 'firebaseui/dist/firebaseui.css';
export default {
	components: {},
	mixins: [clientUserFormHelper],
	data() {
		return {
			email: '',
			password: '',
			error: '',
			emailLogin: false,
			loading: false,
		};
	},
	computed: {
		canSubmit() {
			return !(
				this.email &&
				isEmail(this.email) &&
				this.password &&
				this.password.length >= 6
			);
		},
		isClientUserLogin(){
            return this.getClientOrPreviouslyLoggedId
        }
	},
	mounted() {
		let ui = firebaseui.auth.AuthUI.getInstance();
		if (!ui) {
			ui = new firebaseui.auth.AuthUI(auth);
		}

		if(!this.isClientUserLogin)
		ui.start('#firebaseui-auth-container', {
			privacyPolicyUrl: 'https://www.divii.ca/privacy-policy',
			tosUrl: 'https://www.divii.ca/terms-of-service',
			signInSuccessUrl: '/',
			signInOptions: [googleProvider],
		});

		if(this.getclientUserAgreement){
			this.useEmail();
		}

		
	},
	methods: {
		async createAccount() {
			try {
				this.loading = true;
				await auth.createUserWithEmailAndPassword(
					this.email,
					this.password
				);
				const userSnap = await auth.signInWithEmailAndPassword(
					this.email,
					this.password
				);
				this.$store.commit('setLoginProvider', 'EMAIL');
				this.$store.dispatch('login', userSnap.user).then(async () => {				
				console.log(`User ${userSnap.user.email} logged in`);
					if(this.getClientOrPreviouslyLoggedId){
							console.log('==>>>')
							await fb.customersCollection.doc(userSnap.user.uid).set({
								isClientUser : true,
							}, { merge: true }).catch((err)=>{
								console.error('err***',err);
								return ;//reject(err)
							});
							this.loading = false;
					// 		if(this.getPreviouslyLoggedClientUserAgreement)
					// 			localStorage.setItem('clientUserAgreement', this.getPreviouslyLoggedClientUserAgreement);
					// 		this.$router.push({
					// 			name: 'clientUserAgreement',
					// 			params: { id: this.getClientOrPreviouslyLoggedId },
					// 		});
					} else {
							this.loading = false;
							this.$router.push({ name: 'postLogin' });
					}
				});

			} catch (error) {
				// TODO Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				console.error(errorCode, errorMessage);
				if (errorCode && errorCode == 'auth/email-already-in-use') {
					this.error = errorMessage;
				} else
				this.error =
					'There was a problem setting up your account please try again.';
				this.loading = false;
			}
		},
		hasAccount() {
			this.$router.push({ name: 'login' });
		},
		useEmail() {
			this.emailLogin = true;
		},
	},
};
</script>

<style>
.wrapper-row {
	height: calc(100vh - 170px);
}

.trial-wrapper {
	margin-top: 20px;
	max-width: 500px;
	z-index: 2;
}

.circle-one {
	fill: #d4f0f0;
	opacity: 1;
	left: -40px;
	top: 100px;
	position: absolute;
}

.circle-two {
	fill: #e1efba;
	opacity: 0.6;
	left: 100px;
	top: 660px;
	position: absolute;
}

.circle-three {
	fill: #e1efba;
	opacity: 0.6;
	right: 80px;
	top: 150px;
	position: absolute;
}

.circle-four {
	fill: #d4f0f0;
	opacity: 0.6;
	right: 185px;
	top: 700px;
	position: absolute;
}

.circle-five {
	fill: #e1efba;
	opacity: 0.6;
	left: -80px;
	top: 50px;
	position: absolute;
}

.circle-six {
	fill: #d4f0f0;
	opacity: 1;
	left: -85px;
	top: 180px;
	position: absolute;
}
</style>
